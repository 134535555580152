import { yupResolver } from '@hookform/resolvers/yup';
import { PhoneContainer, TextInput } from 'components/Contact/Form/styled';
import DatePickerInput from 'components/Form/DatePickerInput';
import DateRangePicker from 'components/Form/DateRangePicker';
import selectStyles from 'components/Form/selectStyles';
import SubmitButton from 'components/Form/SubmitButton';
import FromPrice from 'components/Rental/Show/FromPrice';
import { differenceInCalendarDays, format, parseISO, parseJSON } from 'date-fns';
import { enUS, fr as frFR } from 'date-fns/locale';
import { sizes } from 'device';
import useLanguage from 'hooks/useLanguage';
import { useResponsiveMin } from 'hooks/useResponsive';
import useStickyState from 'hooks/useStickyState';
import React, {
    ChangeEvent,
    createRef,
    FormEvent,
    ReactElement,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { HotelDatepickerRef, InputElementProps } from 'react-hotel-datepicker';
import { Trans, useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import fr from 'react-phone-input-2/lang/fr.json';
import { useMutation, useQuery } from 'react-query';
import Recaptcha from 'react-recaptcha';
import Select, { OptionTypeBase } from 'react-select';
import Sticky from 'react-sticky-el';
import { createEnquiry } from 'services/customer';
import { unavailabilities } from 'services/rental';
import { Rental, Unavailability } from 'types/api';
import { EnquireNow, Form, Gdpr, Label, MinBookingDaysWarning, Success } from './styled';
import schema from './validation';

type Props = {
    rental: Rental;
    onClose: () => any;
};

const StickyForm = ({ rental, onClose }: Props): ReactElement => {
    const { t } = useTranslation(['form', 'rental']);
    const { f639 } = useLanguage();
    const dateLocale = f639 === 'fr' ? frFR : enUS;
    const [periodStart, setPeriodStart] = useStickyState<string>('periodStart', '');
    const [periodEnd, setPeriodEnd] = useStickyState<string>('periodEnd', '');
    const [bedrooms, setBedrooms] = useStickyState<number>('bedrooms', 1, false, (value) => parseInt(value, 10));
    const [guests, setGuests] = useStickyState<number>('guests', 2, false, (value) => parseInt(value, 10));
    const [firstName, setFirstName] = useStickyState<string>('firstName', '');
    const [lastName, setLastName] = useStickyState<string>('lastName', '');
    const [email, setEmail] = useStickyState<string>('email', '');
    const [phoneNumber, setPhoneNumber] = useStickyState<string>('phoneNumber', '');
    const [specialRequest, setSpecialRequest] = useStickyState<string>('specialRequest', '');
    const [submittingError, setSubmittingError] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [country, setCountry] = useState<string>(f639);
    const [sticky, setSticky] = useState(true);
    const [minBookingDays, setMinBookingDays] = useState<{ min: number; booking: number }>({ min: 0, booking: 0 });
    const [checkins, setCheckins] = useState<Array<Date>>([]);
    const [checkouts, setCheckouts] = useState<Array<Date>>([]);
    const { data } = useQuery(['rental_unavailabilities', rental.id], () => unavailabilities(rental.id), {
        select: (response): Array<Unavailability> => response.data['hydra:member'],
    });

    const form = useForm<EnquiryFormValues>({
        defaultValues: {
            periodStart,
            periodEnd,
            bedrooms: Math.min(bedrooms, rental.bedroomCount),
            guests: Math.min(guests, rental.capacity),
            firstName,
            lastName,
            email,
            phoneNumber,
            specialRequest,
        },
        resolver: (data, context, options) => yupResolver(schema)(data, { country }, options),
    });

    const {
        control,
        handleSubmit,
        register,
        setValue,
        watch,
        formState: { errors },
    } = form;

    const reCaptchaInstance = createRef<Recaptcha>();
    const formRef = useRef<HTMLFormElement>(null);
    const datePickerRef = useRef<HotelDatepickerRef>(null);
    const stickyRef = useRef<HTMLDivElement>(null);
    const watchedPeriodStart = watch('periodStart');
    const watchedPeriodEnd = watch('periodEnd');

    useEffect(() => {
        if (!data) {
            return;
        }
        setCheckins(data.map((item) => parseJSON(item.periodStart)));
        setCheckouts(data.map((item) => parseJSON(item.periodEnd)));

        if (watchedPeriodStart.length > 0 && watchedPeriodEnd.length > 0) {
            try {
                const unavailability = data.findIndex(
                    (unavailability) =>
                        (unavailability.periodStart < watchedPeriodEnd &&
                            unavailability.periodEnd > watchedPeriodEnd) ||
                        (unavailability.periodStart < watchedPeriodStart &&
                            unavailability.periodEnd > watchedPeriodEnd),
                );
                if (unavailability > -1) {
                    setValue('periodStart', '');
                    setValue('periodEnd', '');
                    datePickerRef?.current?.clear();
                }
            } catch (_e) {
                //Can't parse dates
            }
        }
    }, [data, watchedPeriodStart, watchedPeriodEnd]);

    useEffect(() => {
        if (!watchedPeriodStart || !watchedPeriodEnd) {
            setMinBookingDays({ min: 0, booking: 0 });
        }
        const start = parseISO(watchedPeriodStart);
        const end = parseISO(watchedPeriodEnd);
        const price = (rental.prices || [])
            .filter((item) => item.season)
            .find((item) => parseISO(item.season.periodStart) <= start && parseISO(item.season.periodEnd) >= end);
        const rentalSeason = (rental.rentalSeasons || []).find(
            (rentalSeason) => rentalSeason.season?.id === price?.season?.id,
        );

        const min = rentalSeason?.minBookingDays ?? price?.season?.minBookingDays ?? 0;

        const booking = differenceInCalendarDays(end, start);

        setMinBookingDays({ min, booking });
    }, [watchedPeriodStart, watchedPeriodEnd, rental]);

    const clickCallback = useCallback(
        (event: Event): void => {
            const target = event.target as Node | null;
            if (!stickyRef || !stickyRef.current || !target) {
                return;
            }

            if (stickyRef.current.contains(target) && stickyRef.current.offsetHeight > window.innerHeight - 100) {
                if (sticky) {
                    setSticky(false);
                    stickyRef.current.scrollIntoView();
                }
            } else {
                if (!stickyRef.current.contains(target)) {
                    setSticky(true);
                }
            }
        },
        [stickyRef, sticky],
    );

    useEffect(() => {
        document.addEventListener('click', clickCallback);
        return (): void => {
            document.removeEventListener('click', clickCallback);
        };
    }, [clickCallback]);

    const mutation = useMutation(['request_contact'], (data: EnquiryPostData) => createEnquiry(data));

    const bedroomOptions: Array<OptionTypeBase> = [];
    for (let i = 1; i <= rental.bedroomCount; i++) {
        bedroomOptions.push({
            value: i.toString(),
            label: t('enquiry.bedroom_option', { count: i }),
        });
    }

    const guestOptions: Array<OptionTypeBase> = [];
    for (let i = 1; i <= rental.capacity; i++) {
        guestOptions.push({
            value: i.toString(),
            label: t('enquiry.guest_option', { count: i }),
        });
    }

    const onSubmit = (data: EnquiryPostData): void => {
        setSubmittingError(false);
        setSubmitted(false);

        setPeriodStart(data.periodStart);
        setPeriodEnd(data.periodEnd);
        setBedrooms(data.bedrooms);
        setGuests(data.guests);
        setFirstName(data.firstName);
        setLastName(data.lastName);
        setEmail(data.email);
        setPhoneNumber(data.phoneNumber);
        setSpecialRequest(data.specialRequest);

        mutation
            .mutateAsync(data)
            .then(() => {
                setSubmitted(true);
            })
            .catch(() => {
                setSubmitted(false);
                setSubmittingError(true);
            });
    };

    const executeCaptcha = (event: FormEvent): void => {
        event.preventDefault();
        setSubmitted(false);
        reCaptchaInstance.current?.execute();
    };

    const startDate = watchedPeriodStart.length > 0 ? parseISO(watchedPeriodStart) : null;
    const endDate = watchedPeriodEnd.length > 0 ? parseISO(watchedPeriodEnd) : null;
    const { name } = register('phoneNumber');

    const isXl = useResponsiveMin(sizes.xl);

    const top = isXl ? 80 : 65;

    return (
        <React.Fragment>
            <div className="d-none d-xl-block">
                <FromPrice rental={rental} />
            </div>
            <Sticky
                topOffset={-top}
                bottomOffset={top + 10}
                stickyStyle={{ marginTop: top }}
                disabled={!isXl || !sticky}
                boundaryElement=".rental-container"
            >
                <div ref={stickyRef}>
                    <div className="d-block d-xl-none">
                        <FromPrice rental={rental} onClose={onClose} />
                    </div>
                    <EnquireNow>
                        <Trans t={t}>rental:show.enquiry.book_now</Trans>
                    </EnquireNow>
                    {submitted && startDate && endDate && (
                        <Success>
                            <hr />
                            <div className="my-2">
                                <div className="butler" />
                                <p>
                                    <Trans t={t}>rental:show.enquiry.thanks.chose</Trans>
                                </p>
                                <div className="spacer" />
                                <p className="blue">
                                    <Trans
                                        t={t}
                                        values={{
                                            rental: rental.title,
                                            from: format(startDate, 'PPP', { locale: dateLocale }),
                                            to: format(endDate, 'PPP', { locale: dateLocale }),
                                        }}
                                    >
                                        rental:show.enquiry.thanks.enquiry
                                    </Trans>
                                </p>
                                <div className="spacer" />
                                <p>
                                    <Trans t={t}>rental:show.enquiry.thanks.delay</Trans>
                                </p>
                                <p className="signature text-uppercase">
                                    <Trans t={t}>rental:show.enquiry.thanks.signature</Trans>
                                </p>
                            </div>
                            <hr />
                        </Success>
                    )}
                    {!submitted && (
                        <FormProvider {...form}>
                            <Form ref={formRef}>
                                {submittingError && (
                                    <Alert variant="danger">
                                        <Trans t={t}>rental:show.enquiry.error</Trans>
                                    </Alert>
                                )}
                                <Label>
                                    <Trans t={t}>rental:show.enquiry.when</Trans>
                                </Label>
                                <DateRangePicker
                                    input={({ value, onClick }: InputElementProps): ReactElement => (
                                        <DatePickerInput
                                            value={value}
                                            onClick={onClick}
                                            handleClear={(): void => datePickerRef.current?.clear()}
                                        />
                                    )}
                                    defaultStart={startDate}
                                    defaultEnd={endDate}
                                    ref={datePickerRef}
                                    noCheckInDates={checkins}
                                    noCheckOutDates={checkouts}
                                    align="right"
                                />
                                {minBookingDays.min > minBookingDays.booking && (
                                    <MinBookingDaysWarning>
                                        <div className="butler" />
                                        <div className="float-right">
                                            <Trans t={t} count={parseInt(minBookingDays.min.toString(), 10)}>
                                                rental:show.enquiry.min_booking_days
                                            </Trans>
                                        </div>
                                    </MinBookingDaysWarning>
                                )}
                                <Label>
                                    <Trans t={t}>rental:show.enquiry.how_many</Trans>
                                </Label>
                                <Row className="justify-content-between">
                                    <Col xs={6} className="pe-3">
                                        <Controller
                                            control={control}
                                            name="bedrooms"
                                            render={({ field }): ReactElement => {
                                                const handleChange = (value: any): void => field.onChange(value.value);

                                                return (
                                                    <div className="position-relative">
                                                        <Select
                                                            placeholder={t('enquiry.bedrooms')}
                                                            options={bedroomOptions}
                                                            styles={selectStyles}
                                                            isSearchable={false}
                                                            {...field}
                                                            value={bedroomOptions.find(
                                                                (option) =>
                                                                    parseInt(option.value, 10) ===
                                                                    parseInt(field.value.toString(), 10),
                                                            )}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                );
                                            }}
                                        />
                                    </Col>
                                    <Col xs={6} className="ps-3">
                                        <Controller
                                            control={control}
                                            name="guests"
                                            render={({ field }): ReactElement => {
                                                const handleChange = (value: any): void => field.onChange(value.value);

                                                return (
                                                    <div className="position-relative">
                                                        <Select
                                                            placeholder={t('enquiry.guests')}
                                                            options={guestOptions}
                                                            styles={selectStyles}
                                                            isSearchable={false}
                                                            {...field}
                                                            value={guestOptions.find(
                                                                (option) =>
                                                                    parseInt(option.value, 10) ===
                                                                    parseInt(field.value.toString(), 10),
                                                            )}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                );
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Label>
                                    <Trans t={t}>rental:show.enquiry.contact</Trans>
                                </Label>
                                <div className="has-validation my-2">
                                    <TextInput
                                        placeholder={`${t('form:contact.first_name')} *`}
                                        {...register('firstName')}
                                        className={errors.firstName ? 'is-invalid' : ''}
                                    />
                                    {errors.firstName && (
                                        <div className="invalid-feedback">
                                            <Trans t={t}>{errors.firstName.message}</Trans>
                                        </div>
                                    )}
                                </div>
                                <div className="has-validation my-2">
                                    <TextInput
                                        placeholder={`${t('form:contact.last_name')} *`}
                                        {...register('lastName')}
                                        className={errors.lastName ? 'is-invalid' : ''}
                                    />
                                    {errors.lastName && (
                                        <div className="invalid-feedback">
                                            <Trans t={t}>{errors.lastName.message}</Trans>
                                        </div>
                                    )}
                                </div>
                                <div className="has-validation my-2">
                                    <TextInput
                                        placeholder={`${t('form:contact.email')} *`}
                                        {...register('email')}
                                        className={errors.email ? 'is-invalid' : ''}
                                    />
                                    {errors.email && (
                                        <div className="invalid-feedback">
                                            <Trans t={t}>{errors.email.message}</Trans>
                                        </div>
                                    )}
                                </div>
                                <div className="has-validation my-2">
                                    <PhoneContainer className={errors.phoneNumber ? 'is-invalid' : ''}>
                                        <PhoneInput
                                            value={phoneNumber}
                                            localization={f639 === 'fr' ? fr : undefined}
                                            country={f639 === 'fr' ? 'fr' : 'us'}
                                            placeholder={`${t('form:contact.phone')} *`}
                                            inputProps={{
                                                className: errors.phoneNumber
                                                    ? 'form-control is-invalid'
                                                    : 'form-control',
                                                name,
                                            }}
                                            onChange={(
                                                _value,
                                                country: { countryCode: string },
                                                _e,
                                                formattedValue,
                                            ): void => {
                                                setCountry(country.countryCode);
                                                setValue('phoneNumber', formattedValue);
                                            }}
                                        />
                                    </PhoneContainer>
                                    {errors.phoneNumber && (
                                        <div className="invalid-feedback">
                                            <Trans t={t}>{errors.phoneNumber.message}</Trans>
                                        </div>
                                    )}
                                </div>
                                <div className="has-validation my-2">
                                    <Gdpr
                                        label={<Trans t={t} i18nKey="form:enquiry.gdpr" />}
                                        {...register('gdpr')}
                                        className={errors.gdpr ? 'is-invalid' : ''}
                                        onChange={(event: ChangeEvent<HTMLInputElement>): void =>
                                            setValue('gdpr', event?.target?.checked || false)
                                        }
                                    />
                                    {errors.gdpr && (
                                        <div className="invalid-feedback">
                                            <Trans t={t}>{errors.gdpr.message}</Trans>
                                        </div>
                                    )}
                                </div>
                                <SubmitButton
                                    className="text-uppercase w-100"
                                    onClick={executeCaptcha}
                                    id="enquiry_submit"
                                >
                                    <Trans t={t}>form:enquiry.submit</Trans>
                                </SubmitButton>
                                <Recaptcha
                                    sitekey="6LcQKZsUAAAAAFiEBmr9tn8JT5gNKZEMacZrNH8f"
                                    size="invisible"
                                    verifyCallback={(response): void => {
                                        setSubmitted(false);
                                        handleSubmit((data) =>
                                            onSubmit({
                                                ...data,
                                                locale: f639 === 'fr' ? '/locales/1' : '/locales/2',
                                                gRecaptchaToken: response,
                                                selections: [rental.id],
                                            }),
                                        )();
                                    }}
                                    ref={reCaptchaInstance}
                                    hl={f639}
                                    badge="inline"
                                />
                            </Form>
                        </FormProvider>
                    )}
                </div>
            </Sticky>
        </React.Fragment>
    );
};

export default StickyForm;
