import styled from 'styled-components';

const Container = styled.div.attrs(() => ({
    className: 'd-xl-none position-fixed py-3 px-2',
}))`
    background-color: ${(props): string => props.theme.ivr.colors.white['100']};
    bottom: 0;
    max-width: 100%;
    width: 100vw;
    z-index: 10;

    button {
        background-color: ${(props): string => props.theme.ivr.colors.golden['100']};
        border-radius: 3px;
        color: ${(props): string => props.theme.ivr.colors.white['100']};
        text-transform: uppercase;
    }
`;

const FromPrice = styled.div.attrs(() => ({
    className: 'row justify-content-between g-0',
}))`
    color: ${(props): string => props.theme.ivr.colors.golden['100']};
    font-size: 0.9rem;
    font-weight: 300;

    span {
        color: ${(props): string => props.theme.ivr.colors.black['100']};
        font-size: 1.2rem;
        font-weight: 700;
    }
`;

export { Container, FromPrice };
