import { Cloudinary } from '@cloudinary/url-gen';

const cloudConfig = new Cloudinary({
    cloud: {
        cloudName: 'labsbh-orion',
    },
    url: {
        secure: true,
    },
});

const useCloudinary = (): Cloudinary => cloudConfig;

export default useCloudinary;
