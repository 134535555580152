import { unavailabilities } from 'services/rental';
import { parseJSON } from 'date-fns';
import { fr, enUS } from 'date-fns/locale';
import useLanguage from 'hooks/useLanguage';
import React, { ReactElement, useEffect, useState } from 'react';
import { HotelCalendarTheme } from 'react-hotel-calendar';
import { Trans, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useTheme } from 'styled-components';
import { Rental, Unavailability } from 'types/api';
import { ivrTheme } from 'components/Layout/styled';
import { HotelCalendar, Translations } from 'react-hotel-calendar';
import { AvailabilityMention, StyledHotelCalendar } from './styled';

const translations: Record<'fr' | 'en', Translations> = {
    fr: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        no_checkin: '{{date}} <br/>Départ possible',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        not_available: '{{date}} <br/>Indisponible',
    },
    en: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        no_checkin: '{{date}} <br/>Possible departure',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        not_available: '{{date}} <br/>Booked',
    },
};

type Props = {
    rental: Rental;
};

const Calendar = ({ rental }: Props): ReactElement | null => {
    const { t } = useTranslation('rental');
    const { f639 } = useLanguage();
    const locale = f639 === 'fr' ? fr : enUS;
    const defaultTheme = useTheme() as typeof ivrTheme;

    const theme: HotelCalendarTheme = {
        fontFamily: 'var(--bs-body-font-family)',
        calendar: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            border: `1px solid ${defaultTheme.ivr.colors.golden['100']}`,
            borderRadius: '0',
            color: defaultTheme.ivr.colors.golden['100'],
            widths: {
                sm: '100%',
                md: '100%',
                lg: '100%',
            },
        },
        months: {
            spacer: {
                color: defaultTheme.ivr.colors.golden['100'],
            },
            table: {
                borderColor: 'transparent',
                navButton: {
                    backgroundColor: {
                        base: 'transparent',
                        hover: 'transparent',
                    },
                    textColor: {
                        base: defaultTheme.ivr.colors.golden['100'],
                        hover: defaultTheme.ivr.colors.golden['100'],
                    },
                },
                widths: {
                    sm: '100%',
                    md: '49%',
                    lg: '49%',
                },
            },
        },
        days: {
            textColor: defaultTheme.ivr.colors.black['100'],
            today: {
                backgroundColor: defaultTheme.ivr.colors.golden['100'],
            },
            noCheckIn: {
                backgroundColor: defaultTheme.ivr.colors.golden['100'],
                textColor: defaultTheme.ivr.colors.black['100'],
            },
            noCheckOut: {
                backgroundColor: defaultTheme.ivr.colors.golden['100'],
                textColor: defaultTheme.ivr.colors.black['100'],
            },
            disabled: {
                backgroundColor: defaultTheme.ivr.colors.golden['100'],
                crossColor: 'transparent',
                textColor: defaultTheme.ivr.colors.white['100'],
            },
            invalid: {
                textColor: defaultTheme.ivr.colors.black['50'],
            },
        },
        tooltip: {
            backgroundColor: defaultTheme.ivr.colors.golden['100'],
            textColor: defaultTheme.ivr.colors.white['100'],
            fontSize: '12px',
            padding: '0.5rem',
        },
    };

    const [checkins, setCheckins] = useState<Array<Date>>([]);
    const [checkouts, setCheckouts] = useState<Array<Date>>([]);
    const { data, isLoading } = useQuery(
        ['rental_unavailabilities', rental.id],
        () => unavailabilities(rental.id, rental.pullPlanning),
        {
            select: (response): Array<Unavailability> => response.data['hydra:member'],
        },
    );

    useEffect(() => {
        if (!data) {
            return;
        }
        setCheckins(data.map((item) => parseJSON(item.periodStart)));
        setCheckouts(data.map((item) => parseJSON(item.periodEnd)));
    }, [data]);

    if (isLoading) {
        return null;
    }

    return (
        <>
            <StyledHotelCalendar>
                <HotelCalendar
                    format="PPP"
                    locale={locale}
                    noCheckInDates={checkins}
                    noCheckOutDates={checkouts}
                    theme={theme}
                    disabledDatesBetweenChecks
                    i18n={translations[f639 as 'fr']}
                />
            </StyledHotelCalendar>
            <AvailabilityMention>
                <Trans t={t}>rental:show.availabilities.mentions</Trans>
            </AvailabilityMention>
        </>
    );
};

export default Calendar;
