import { fill } from '@cloudinary/url-gen/actions/resize';
import GreyContainer from 'components/Layout/GreyContainer';
import Calendar from 'components/Rental/Show/Calendar';
import Prices from 'components/Rental/Show/Prices';
import RentalCarousel from 'components/Rental/Show/RentalCarousel';
import RoomType from 'components/Rental/Show/RoomType';
import SimilarRentals from 'components/Rental/Show/SimilarRentals';
import StickyButton from 'components/Rental/Show/StickyButton';
import StickyForm from 'components/Rental/Show/StickyForm';
import { graphql, useStaticQuery } from 'gatsby';
import GoogleMap from 'google-map-react';
import useCloudinary from 'hooks/useCloudinary';
import useLanguage from 'hooks/useLanguage';
import useSSR from 'hooks/useSSR';
import useTranslatedFields from 'hooks/useTranslatatedField';
import bathroom from 'images/rental/bathroom.png';
import bedroom from 'images/rental/bedroom.png';
import guest from 'images/rental/guest.png';
import React, { ReactElement, useState } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import { IoMdPin } from 'react-icons/io';
import slugify from 'slugify';
import { Highlight, RentalPointOfInterest, RoomType as RoomTypeType, Rental as RentalType } from 'types/api';
import {
    Block,
    Data,
    Description,
    Highlights,
    IncludedService,
    MapContainer,
    MapPin,
    Pois,
    RentalColumn,
    RentalContainer,
    SeeOnMap,
    SideColumn,
    Spacer,
    SpecialOffer,
    Top,
} from './styled';
import { format, isAfter, isBefore, parseJSON } from 'date-fns';
import { enUS, fr } from 'date-fns/locale';

type Props = {
    pageContext: {
        rental: RentalType;
    };
};

const Rental = ({ pageContext: { rental } }: Props): ReactElement => {
    const {
        allRoomType: { nodes: roomTypes },
    } = useStaticQuery(query);
    const { t } = useTranslation();
    const { f639, f3166 } = useLanguage();
    const translateField = useTranslatedFields();
    const [visibleOnMobile, setVisibleOnMobile] = useState(false);
    const { browser } = useSSR();
    const cloudinary = useCloudinary();

    const mapProps = {
        url: {
            key: 'AIzaSyCg9zcV_LNBd5ljIYrpdy08o7WPGxcIpfo',
            language: f639,
            region: f3166,
        },
        center: {
            lat: 17.908251,
            lng: -62.837978,
        },
        zoom: 13,
        options: {
            mapTypeControl: true,
            mapTypeId: 'roadmap',
            styles: [{ featureType: 'all', stylers: [] }],
        },
    };

    let seo = 'villa';
    if (rental.city) {
        seo = `${seo}-${rental.city}`;
    }
    if (rental.location.title) {
        seo = `${seo}-${rental.location.title}`;
    }
    seo = slugify(seo).toLowerCase();

    const url = `/${'fr' === f639 ? 'locations' : 'rentals'}/${seo}/${rental.slug}`;

    let mainPictureUrl = `${process.env.REACT_APP_SITE_URL}images/logo.png`;
    if (rental.pictures?.[0]) {
        if (rental.pictures[0].cdnId) {
            mainPictureUrl = cloudinary
                .image(`${rental.pictures[0].cdnId}.${rental.pictures[0].format}`)
                .resize(fill(1200, 630))
                .toURL();
        } else {
            mainPictureUrl = rental.pictures[0].contentUrl;
        }
    }

    return (
        <React.Fragment>
            <Helmet>
                <meta property="og:url" content={`${process.env.REACT_APP_SITE_URL}${url}`} />
                <meta property="og:site_name" content={t('rental:list.meta.og.siteName')} />
                <meta property="og:type" content="website" />
                <meta
                    property="og:title"
                    content={t('rental:show.meta.og.title', {
                        values: {
                            title: rental.title,
                        },
                    })}
                />
                <meta
                    property="og:description"
                    content={translateField(rental, 'description')
                        .replace(/(<([^>]+)>)/gi, '')
                        .substr(0, 300)}
                />
                <meta property="og:image" content={mainPictureUrl} />
                <meta
                    property="twitter:title"
                    content={t('rental:show.meta.og.title', {
                        values: {
                            title: rental.title,
                        },
                    })}
                />
                <meta
                    property="twitter:description"
                    content={translateField(rental, 'description')
                        .replace(/(<([^>]+)>)/gi, '')
                        .substr(0, 300)}
                />
                <meta property="twitter:image" content={mainPictureUrl} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta
                    property="title"
                    content={t('rental:show.meta.og.title', {
                        values: {
                            title: rental.title,
                        },
                    })}
                />
                <title>
                    {t('rental:show.meta.og.title', {
                        values: {
                            title: rental.title,
                        },
                    })}
                </title>
                <meta
                    property="description"
                    content={translateField(rental, 'description')
                        .replace(/(<([^>]+)>)/gi, '')
                        .substr(0, 300)}
                />
            </Helmet>
            <Container fluid className="g-0">
                <RentalCarousel pictures={rental.pictures} title={rental.title} />
            </Container>
            <GreyContainer>
                <RentalContainer>
                    <Row>
                        <RentalColumn>
                            <Top>
                                <Col xs={6} className="d-none d-xl-block pb-2 align-self-end">
                                    <h1>{rental.title}</h1>
                                </Col>
                                <Col xs={2} className="d-none d-xl-block pb-2 align-self-end location">
                                    <IoMdPin className="mr-2" />
                                    {rental.location.title}
                                </Col>
                                <Col xs="auto" className="d-block d-xl-none pb-2 align-self-end">
                                    <h1>{rental.title}</h1>
                                    <div className="location">
                                        <IoMdPin className="mr-2" />
                                        {rental.location.title}
                                    </div>
                                </Col>
                                <SeeOnMap>
                                    <a href="#map">
                                        <Trans t={t}>rental:show.map</Trans>
                                    </a>
                                </SeeOnMap>
                            </Top>
                            {(rental.specialOffers?.length || 0) > 0 && (
                                <div className="row text-center pt-4">
                                    <SpecialOffer>
                                        <div className="special-offer-title text-uppercase">
                                            <Trans t={t} count={(rental.specialOffers || []).length}>
                                                rental:show:special_offers
                                            </Trans>
                                        </div>
                                        <div>
                                            <hr />
                                            {(rental.specialOffers || []).filter((specialOffer) => isBefore(parseJSON(specialOffer.periodStart), new Date()) && (!specialOffer.periodEnd || isAfter(parseJSON(specialOffer.periodEnd), new Date()))).map((specialOffer) => (
                                                <div key={specialOffer['@id']} className="mb-1">
                                                    {translateField(specialOffer, 'title')}
                                                    {specialOffer.periodEnd && (
                                                        <Trans
                                                            t={t}
                                                            values={{
                                                                start: specialOffer.periodStart ? format(parseJSON(specialOffer.periodStart), 'PP', {
                                                                    locale: f639 === 'fr' ? fr : enUS,
                                                                }) : '',
                                                                end: specialOffer.periodEnd ? format(parseJSON(specialOffer.periodEnd), 'PP', {
                                                                    locale: f639 === 'fr' ? fr : enUS,
                                                                }) : '',
                                                            }}
                                                        >
                                                            rental:show.special_offer
                                                        </Trans>
                                                    )}
                                                </div>
                                            ))}
                                            <hr />
                                        </div>
                                    </SpecialOffer>
                                </div>
                            )}
                            <Row className="justify-content-center py-4">
                                <Data>
                                    <Image src={guest} alt="guests" className="me-2" />
                                    <Trans t={t} count={parseInt(rental.capacity.toString(), 10)}>
                                        rental:show.capacity
                                    </Trans>
                                </Data>
                                <Data>
                                    <Image src={bedroom} alt="bedrooms" className="me-2" />
                                    <Trans t={t} count={parseInt(rental.bedroomCount.toString(), 10)}>
                                        rental:show.bedroom
                                    </Trans>
                                </Data>
                                <Data>
                                    <Image src={bathroom} alt="bathrooms" className="me-2" />
                                    <Trans t={t} count={parseInt(rental.bathroomCount.toString(), 10)}>
                                        rental:show.bathroom
                                    </Trans>
                                </Data>
                            </Row>
                            <Row>
                                <Spacer>
                                    <div className="px-5 py-1">
                                        <Trans t={t}>rental:show.highlights</Trans>
                                    </div>
                                </Spacer>
                            </Row>
                            <Row>
                                <Highlights>
                                    {(rental.highlights || [])
                                        .map((highlight: Highlight) => translateField(highlight, 'title'))
                                        .join(' • ')}
                                </Highlights>
                            </Row>
                            <Block>
                                <h2>
                                    <Trans t={t}>rental:show.description</Trans>
                                </h2>
                                <Description
                                    dangerouslySetInnerHTML={{
                                        // eslint-disable-next-line
                                        __html: translateField(rental, 'description'),
                                    }}
                                />
                            </Block>
                            <Block>
                                <h2>
                                    <Trans t={t}>rental:show.rooms</Trans>
                                </h2>
                                <div>
                                    {roomTypes.map((roomType: RoomTypeType) => (
                                        <RoomType key={roomType.id} rooms={rental.rooms || []} roomType={roomType} />
                                    ))}
                                </div>
                            </Block>
                            <Block>
                                <h2>
                                    <Trans t={t}>rental:show.included_services.title</Trans>
                                </h2>
                                <div>
                                    <ul>
                                        <IncludedService>
                                            <Trans t={t}>rental:show.included_services.greet</Trans>
                                        </IncludedService>
                                        <IncludedService>
                                            <Trans t={t}>rental:show.included_services.rental</Trans>
                                        </IncludedService>
                                        <IncludedService>
                                            <Trans t={t}>rental:show.included_services.presentation</Trans>
                                        </IncludedService>
                                        <IncludedService>
                                            <Trans t={t}>rental:show.included_services.basket</Trans>
                                        </IncludedService>
                                        <IncludedService>
                                            <Trans t={t}>rental:show.included_services.cleaning</Trans>
                                        </IncludedService>
                                        <IncludedService>
                                            <Trans t={t}>rental:show.included_services.butler</Trans>
                                        </IncludedService>
                                        <IncludedService>
                                            <Trans t={t}>rental:show.included_services.bagage</Trans>
                                        </IncludedService>
                                        <IncludedService>
                                            <Trans t={t}>rental:show.included_services.leaving</Trans>
                                        </IncludedService>
                                    </ul>
                                </div>
                            </Block>
                            <Block>
                                <h2>
                                    <Trans t={t}>rental:show.prices.title</Trans>
                                </h2>
                                <div>
                                    <Prices rental={rental} />
                                </div>
                            </Block>
                            <Block>
                                <h2>
                                    <Trans t={t}>rental:show.availabilities.title</Trans>
                                </h2>
                                <div>{browser && <Calendar rental={rental} />}</div>
                            </Block>
                            <Block>
                                <h2>
                                    <Trans t={t}>rental:show.location.title</Trans>
                                </h2>
                                <div>
                                    <Pois>
                                        <tbody>
                                        {(rental.pointOfInterests || []).map((poi: RentalPointOfInterest) => (
                                            <tr key={poi.pointOfInterest.id}>
                                                <th>{translateField(poi.pointOfInterest, 'title')}</th>
                                                <td>
                                                    <strong>
                                                        {poi.value} {poi.pointOfInterest?.unit || ''}
                                                    </strong>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </Pois>
                                    {browser && (
                                        <MapContainer>
                                            <a id="map" />
                                            <GoogleMap
                                                defaultZoom={mapProps.zoom}
                                                defaultCenter={mapProps.center}
                                                bootstrapURLKeys={mapProps.url}
                                                options={mapProps.options}
                                            >
                                                <MapPin lat={rental.lat} lng={rental.lng} />
                                            </GoogleMap>
                                        </MapContainer>
                                    )}
                                </div>
                            </Block>
                        </RentalColumn>
                        {browser && (
                            <SideColumn $visible={visibleOnMobile}>
                                <StickyForm rental={rental} onClose={(): void => setVisibleOnMobile(false)} />
                            </SideColumn>
                        )}
                    </Row>
                </RentalContainer>
            </GreyContainer>
            {browser && <SimilarRentals rental={rental} />}
            {browser && (
                <StickyButton
                    rental={rental}
                    toggle={(): void => setVisibleOnMobile(true)}
                    visible={!visibleOnMobile}
                />
            )}
        </React.Fragment>
    );
};

export default Rental;

const query = graphql`
    query {
        allRoomType {
            nodes {
                id
                code
                bedroom
                translations {
                    en_US {
                        title
                    }
                    fr_FR {
                        title
                    }
                }
            }
        }
    }
`;
