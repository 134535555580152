import { cssBreakpoint } from 'device';
import styled from 'styled-components';
import { Col, Container, Row } from 'react-bootstrap';
import seeOnMap from 'images/rental/see-on-map.png';
import Pin from 'images/rental/pin.png';

const RentalContainer = styled(Container).attrs(() => ({
    className: 'g-4 rental-container',
}))`
    position: relative;
    z-index: 10;
    @media screen and ${cssBreakpoint('xl')} {
        margin-top: -60px;
    }
`;

const RentalColumn = styled(Col).attrs(() => ({
    xs: 12,
    xl: 8,
    className: 'mb-4',
}))`
    border-left: 2px solid ${(props): string => props.theme.ivr.colors.white['100']};
    border-right: 2px solid ${(props): string => props.theme.ivr.colors.white['100']};
`;

const SideColumn = styled(Col).attrs(() => ({
    xs: 12,
    xl: 4,
    className: 'px-0',
}))<{ $visible: boolean }>`
    &&& {
        background-color: ${(props): string => props.theme.ivr.colors.white['100']};
        height: 100%;
        max-height: calc(100vh - 65px);
        left: 0;
        opacity: ${(props): number => (props.$visible ? 1 : 0)};
        overflow-y: scroll;
        position: fixed;
        top: 65px;
        transition: visibility 0s, opacity 0.3s, top 0.3s linear;
        visibility: ${(props): string => (props.$visible ? 'visible' : 'hidden')};
        width: 100vw;
        z-index: 99;
        @media screen and ${cssBreakpoint('xl')} {
            background-color: transparent;
            max-height: unset;
            opacity: 1;
            overflow-y: unset;
            position: relative;
            top: 0;
            visibility: visible;
            width: 33.33333333%;
        }
    }
`;

const Top = styled(Row)`
    background-color: ${(props): string => props.theme.ivr.colors.white['100']};
    min-height: 61px;

    h1 {
        font-family: ${(props): string => props.theme.ivr.fonts.playfair};
        font-style: italic;
        font-size: 2rem;
        font-weight: 700;
    }

    .location {
        color: #646464;
        font-size: 0.8rem;
        font-weight: lighter;
        text-transform: uppercase;
        white-space: nowrap;
        @media screen and ${cssBreakpoint('xl')} {
            font-size: 1.2rem;
        }
    }
`;

const SeeOnMap = styled(Col).attrs(() => ({
    className: 'pb-2 ms-auto align-self-center',
    xs: 2,
}))`
    font-size: 0.9rem;
    font-weight: 700;
    line-height: 1rem;
    position: relative;
    text-decoration: underline;

    &::before {
        background-image: url('${seeOnMap}');
        content: '';
        height: 33px;
        left: 10px;
        position: absolute;
        top: -8px;
        transform: translateX(-100%);
        width: 44px;
    }

    a {
        color: ${(props): string => props.theme.ivr.colors.black['100']};

        &:hover {
            color: ${(props): string => props.theme.ivr.colors.black['100']};
        }
    }
`;

const Data = styled(Col).attrs(() => ({
    xs: 'auto',
}))`
    font-size: 1rem;
    padding-left: 10px;
    padding-right: 10px;
    @media screen and ${cssBreakpoint('lg')} {
        font-size: 1.3rem;
        padding-left: 15px;
        padding-right: 15px;
    }
`;

const Spacer = styled(Col).attrs(() => ({
    xs: 12,
    className: 'mb-2 text-uppercase text-center position-relative',
}))`
    &::before {
        content: '';
        left: 0;
        position: absolute;
        top: calc(50% - 1px);
        width: 100%;
        border-bottom: 1px solid ${(props): string => props.theme.ivr.colors.golden['100']};
    }

    & > div {
        background-color: ${(props): string => props.theme.ivr.colors.white['100']};
        color: ${(props): string => props.theme.ivr.colors.golden['100']};
        display: inline-block;
        position: relative;
        text-transform: uppercase;
        z-index: 2;
        border-bottom: 1px solid ${(props): string => props.theme.ivr.colors.golden['100']};
        border-radius: 1px;
        border-top: 1px solid ${(props): string => props.theme.ivr.colors.golden['100']};

        &::after,
        &::before {
            background-color: ${(props): string => props.theme.ivr.colors.white['100']};
            content: '';
            height: 24px;
            position: absolute;
            top: 4px;
            transform-origin: center center;
            width: 24px;
            z-index: -1;
            border-color: transparent transparent ${(props): string => props.theme.ivr.colors.golden['100']}
                ${(props): string => props.theme.ivr.colors.golden['100']};
            border-style: none none solid solid;
            border-width: 0 0 1px 1px;
        }

        &::before {
            left: -13px;
            transform: rotate(45deg);
        }

        &::after {
            right: -13px;
            transform: rotate(-135deg);
        }
    }
`;

const Highlights = styled(Col).attrs(() => ({
    className: 'text-center',
}))`
    font-family: ${(props): string => props.theme.ivr.fonts.playfair};

    font-style: italic;
    @media screen and ${cssBreakpoint('lg')} {
        font-size: 1.3rem;
    }
`;

const Block = styled.div.attrs(() => ({
    className: 'mt-4',
}))`
    h2 {
        display: inline-block;
        font-family: ${(props): string => props.theme.ivr.fonts.playfair};
        font-size: 1.7rem;
        font-style: italic;
        background: ${(props): string => props.theme.ivr.colors.white['100']};
        margin: 0;
        padding: 0.5rem 5rem 0.5rem 1.3rem;
    }

    p {
        margin-bottom: 0;
    }

    > div:first-of-type {
        padding-top: 1rem;
        border-top: 2px solid ${(props): string => props.theme.ivr.colors.white['100']};
    }
`;

const IncludedService = styled.li`
    font-weight: 700;
    list-style: none;
    padding: 0.5rem 0;
    &::before {
        color: ${(props): string => props.theme.ivr.colors.golden['100']};
        content: '\\2022';
        display: inline-block;
        font-weight: 700;
        margin-left: -1em;
        width: 1em;
    }

    small {
        font-weight: normal;
    }
`;

const Pois = styled.table.attrs(() => ({
    className: 'mb-3',
}))`
    th {
        font-weight: 400;
        padding-left: 1rem;
        padding-right: 1rem;
        &::before {
            color: ${(props): string => props.theme.ivr.colors.golden['100']};
            content: '\\2022';
            display: inline-block;
            font-weight: 700;
            margin-left: -1em;
            width: 1em;
        }
    }
`;

const MapContainer = styled.div`
    height: 500px;
    overflow: hidden;
    position: relative;
    width: 100%;
`;

const MapPin = styled.div<{ lat: string; lng: string }>`
    background-image: url('${Pin}');
    background-size: cover;
    height: 51px;
    position: absolute;
    transform: translateX(-50%) translateY(-100%);
    width: 41px;
`;

const Description = styled.div`
    text-align: justify;

    p {
        margin-bottom: 1rem;
    }
`;

const SpecialOffer = styled.div.attrs(() => ({
    className: 'col-12',
}))`
    font-size: 0.8rem;
    hr {
      border-top: 1px dashed ${(props): string => props.theme.ivr.colors.golden['100']};
    }
    .special-offer-title {
      color: ${(props): string => props.theme.ivr.colors.deepBlue['75']};
      font-size: 1rem;
      font-weight: 300;
      
      span {
        color: ${(props): string => props.theme.ivr.colors.golden['100']};
        font-weight: bold;
      }
    }
`;

export {
    Block,
    Data,
    Description,
    Highlights,
    IncludedService,
    MapContainer,
    MapPin,
    Pois,
    RentalColumn,
    RentalContainer,
    SideColumn,
    SeeOnMap,
    Spacer,
    SpecialOffer,
    Top,
};
