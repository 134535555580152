import { Gdpr as BaseGdpr } from 'components/Contact/Form/styled';
import butler from 'images/rental/butler.svg';
import styled from 'styled-components';

const EnquireNow = styled.div.attrs(() => ({
    className: 'ps-2 ps-xl-0 text-left text-xl-center py-2 d-none d-xl-block',
}))`
    background-color: ${(props): string => props.theme.ivr.colors.golden['100']};
    color: ${(props): string => props.theme.ivr.colors.white['100']};
    font-family: ${(props): string => props.theme.ivr.fonts.playfair};
    font-size: 1.6rem;
    font-style: italic;
`;

const Form = styled.form.attrs(() => ({
    className: 'py-2 px-4',
}))`
    background-color: ${(props): string => props.theme.ivr.colors.white['100']};
`;

const Label = styled.p.attrs(() => ({
    className: 'my-0 pb-2 fw-bold',
}))`
    font-size: 0.8rem;
`;

const Gdpr = styled(BaseGdpr)`
    font-size: 0.8rem;
    text-align: justify;
`;

const Success = styled.div.attrs(() => ({
    className: 'p-3 text-center enquiry-success',
}))`
    background-color: ${(props): string => props.theme.ivr.colors.white['100']};

    .butler {
        background-image: url('${butler}');
        border: 1px solid ${(props): string => props.theme.ivr.colors.golden['100']};
        border-radius: 50%;
        fill: ${(props): string => props.theme.ivr.colors.golden['100']};
        height: 50px;
        margin: 1rem auto;
        width: 50px;
    }

    hr {
        border-top: 1px solid ${(props): string => props.theme.ivr.colors.golden['100']};
        margin: 0;
    }

    p {
        color: ${(props): string => props.theme.ivr.colors.golden['100']};

        &.blue {
            color: ${(props): string => props.theme.ivr.colors.deepBlue['100']};
            margin: 2rem auto;
            position: relative;
        }

        &.signature {
            font-family: ${(props): string => props.theme.ivr.fonts.playfair};
        }
    }
`;

const MinBookingDaysWarning = styled.div.attrs(() => ({ className: 'p-1 mt-2' }))`
    align-items: center;
    background-color: ${(props): string => props.theme.ivr.colors.golden['100']};
    border-radius: 0.3rem;
    color: ${(props): string => props.theme.ivr.colors.white['100']};
    display: flex;
    font-size: 0.8rem;
    justify-content: space-between;
    position: relative;

    &::after {
        border: 7px solid transparent;
        border-bottom-color: ${(props): string => props.theme.ivr.colors.golden['100']};
        bottom: 100%;
        content: ' ';
        height: 0;
        left: 50%;
        margin-left: -7px;
        pointer-events: none;
        position: absolute;
        width: 0;
    }

    .butler {
        background-color: ${(props): string => props.theme.ivr.colors.white['100']};
        border-radius: 50%;
        height: 40px;
        position: relative;
        width: 40px;

        &::after {
            background-image: url('${butler}');
            content: ' ';
            height: 30px;
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 30px;
        }
    }

    .float-right {
        width: calc(100% - 50px);
    }
`;

export { EnquireNow, Form, Gdpr, Label, MinBookingDaysWarning, Success };
