import { Price } from 'types/api';

type GroupedPrices = Record<string, Record<number, number>>;

const getGroupedPrices = (prices: Array<Price>): GroupedPrices => {
    const groupedPrices: GroupedPrices = {};
    prices
        .filter((item) => {
            const price = parseFloat(item.price.toString());

            return !isNaN(price) && price > 0;
        })
        .forEach((price) => {
            const season = price.season.id;

            if (undefined === groupedPrices[season]) {
                groupedPrices[season] = {};
            }
            groupedPrices[season][price.bedroomCount] = price.price;
        });

    return groupedPrices;
};

const getRooms = (prices: GroupedPrices): Array<number> => {
    const rooms: Array<number> = [];
    Object.values(prices).forEach((bedrooms) => {
        const keys = Object.keys(bedrooms).map(Number);

        let startIndex: number = keys[0];
        keys.forEach((key: number) => {
            const price = bedrooms[key];
            if (price !== bedrooms[startIndex]) {
                rooms.push(startIndex);
                startIndex = key;
            }
        });
        rooms.push(startIndex);
    });

    return rooms.filter((value, index, self) => self.indexOf(value) === index).sort();
};

const usePriceFormatter = (prices: Array<Price>): [Array<number>, GroupedPrices] => {
    const groupedPrices = getGroupedPrices(prices.filter((price) => price.season));
    const rooms = getRooms(groupedPrices);

    Object.keys(groupedPrices).map((season) =>
        Object.keys(groupedPrices[season])
            .map(Number)
            .reduce((reduced, key) => {
                if (rooms.includes(key)) {
                    reduced.push(groupedPrices[season][key]);
                }

                return reduced;
            }, [] as Array<number>),
    );

    return [rooms, groupedPrices];
};

export default usePriceFormatter;
