import { useRentalPriceRange } from 'hooks/usePrices';
import React, { ReactElement } from 'react';
import { Col } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { Rental } from 'types/api';
import { Container, FromPrice } from './styled';

type Props = {
    rental: Rental;
    toggle: () => any;
    visible: boolean;
};

const StickyButton = ({ rental, toggle, visible }: Props): ReactElement | null => {
    const { t } = useTranslation('rental');
    const data = useRentalPriceRange(rental);

    if (!visible) {
        return null;
    }

    return (
        <Container>
            <FromPrice>
                <Col>
                    <Trans t={t} values={{ price: data.min }}>
                        rental:show.price
                    </Trans>
                </Col>
                <div className="col-auto">
                    <button className="d-inline-block py-2 px-3" onClick={toggle}>
                        <Trans t={t}>rental:show.book_mobile</Trans>
                    </button>
                </div>
            </FromPrice>
        </Container>
    );
};

export default StickyButton;
