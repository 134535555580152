import { graphql, useStaticQuery } from 'gatsby';
import useLanguage from 'hooks/useLanguage';
import usePriceFormatter from 'hooks/usePriceFormatter';
import useTranslatedFields from 'hooks/useTranslatatedField';
import React, { ReactElement, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Rental, Season } from 'types/api';
import { Mentions, PriceTable } from './styled';
import { format, parseJSON } from 'date-fns';
import { fr, enUS } from 'date-fns/locale';

type Props = {
    rental: Rental;
};

const Prices = ({ rental }: Props): ReactElement => {
    const {
        allSeason: { nodes: seasons },
    } = useStaticQuery(query);
    const { f639 } = useLanguage();
    const { t } = useTranslation('rental');
    const [rooms, groupedPrices] = usePriceFormatter(rental.prices || []);
    const translatedField = useTranslatedFields();

    const formatBedroomHeader = useCallback((startIndex: number, endIndex: number): string => {
        if (endIndex - startIndex === 0) {
            return `${startIndex} ${t('rental:show.prices.bedroom', { count: startIndex })}`;
        }

        return t('rental:show.prices.bedroom', {
            context: endIndex - startIndex > 1 ? 'interval' : 'or',
            first: startIndex,
            second: endIndex,
        });
    }, []);

    return (
        <PriceTable>
            <caption>
                <Mentions>
                    <Trans t={t}>rental:show.prices.mentions</Trans>
                </Mentions>
            </caption>
            <thead>
                <tr>
                    <th>
                        <Trans t={t}>rental:show.prices.period</Trans>
                    </th>
                    {rooms.map((room, index) => (
                        <th key={room}>
                            {formatBedroomHeader(
                                room,
                                index === rooms.length - 1 ? rental.bedroomCount || 0 : rooms[index + 1] - 1,
                            )}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {seasons
                    .filter((season: Season) => season.id in groupedPrices)
                    .map((season: Season) => (
                        <tr key={season.id}>
                            <th className="text-nowrap">
                                <Trans
                                    t={t}
                                    values={{
                                        title: translatedField(season, 'title'),
                                        start: format(parseJSON(season.periodStart), 'PP', {
                                            locale: f639 === 'fr' ? fr : enUS,
                                        }),
                                        end: format(parseJSON(season.periodEnd), 'PP', {
                                            locale: f639 === 'fr' ? fr : enUS,
                                        }),
                                    }}
                                >
                                    rental:show.prices.season
                                </Trans>
                            </th>
                            {rooms.map((room) => (
                                <td className="text-nowrap" key={`${room}.${season.id}`}>
                                    {groupedPrices[season.id][room]} $
                                </td>
                            ))}
                        </tr>
                    ))}
            </tbody>
        </PriceTable>
    );
};

export default Prices;

const query = graphql`
    query {
        allSeason {
            nodes {
                id
                minBookingDays
                periodStart
                periodEnd
                translations {
                    fr_FR {
                        title
                        subtitle
                    }
                    en_US {
                        title
                        subtitle
                    }
                }
            }
        }
    }
`;
