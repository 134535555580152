import H1 from 'components/Layout/H1';
import RentalCard from 'components/Rental/Card';
import useStickyState from 'hooks/useStickyState';
import React, { ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useQuery } from 'react-query';
import { similars } from 'services/rental';
import { Rental } from 'types/api';

type Props = {
    rental: Rental;
};

const responsive = {
    mobile: {
        breakpoint: { min: 0, max: 768 },
        items: 1,
    },
    tablet: {
        breakpoint: { min: 768, max: 992 },
        items: 2,
        slidesToSlide: 2,
    },
    desktop: {
        breakpoint: { min: 992, max: 1200 },
        items: 2,
        slidesToSlide: 2,
    },
    superLargeDesktop: {
        breakpoint: { min: 1200, max: 10000 },
        items: 3,
        slidesToSlide: 3,
    },
};

const SimilarRentals = ({ rental }: Props): ReactElement | null => {
    const { t } = useTranslation('rental');
    const { id } = rental;
    const [periodStart] = useStickyState<string>('periodStart', '', false);
    const [periodEnd] = useStickyState<string>('periodEnd', '', false);
    const [bedrooms] = useStickyState<number>('bedrooms', 1, false, (value) => parseInt(value, 10));
    const [highlights] = useStickyState<Array<string>>('highlights', [], false);

    const { data, isLoading } = useQuery(
        ['similar', { id, periodStart, periodEnd, bedrooms, highlights }],
        () => similars(id, periodStart, periodEnd, bedrooms, highlights),
        {
            select: (response): Array<Rental> => response.data['hydra:member'],
        },
    );

    if (isLoading) {
        return null;
    }

    return (
        <div className="text-center">
            <H1 as="h2">
                <Trans t={t}>rental:show.similar</Trans>
            </H1>
            <Carousel responsive={responsive} showDots={false} itemClass="px-3">
                {(data || []).map((item) => (
                    <RentalCard key={rental.id} rental={item} xs={12} />
                ))}
            </Carousel>
        </div>
    );
};

export default SimilarRentals;
