import useTranslatedFields from 'hooks/useTranslatatedField';
import React, { ReactElement } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Room as RoomT, RoomType as RoomTypeType } from 'types/api';
import Room from './Room';
import { RoomTypeBlock } from './styled';

type Props = {
    rooms: Array<RoomT>;
    roomType: RoomTypeType;
};

const RoomType = ({ rooms, roomType }: Props): ReactElement | null => {
    const translatedField = useTranslatedFields();
    const roomsFromType = rooms.filter((room) => room.roomType.id === roomType.id);

    if (roomsFromType.length === 0) {
        return null;
    }

    return (
        <RoomTypeBlock>
            <h4>
                {translatedField(roomType, 'title')}
                {roomsFromType.length > 1 ? 's' : ''}
            </h4>
            {roomsFromType.length > 1 && (
                <Row>
                    {roomsFromType.map((roomFromType, index) => (
                        <Col xs={12} xl={6} className="my-3" key={roomFromType.id}>
                            <Room
                                room={roomFromType}
                                index={index + 1}
                                withTitle={true}
                                roomType={translatedField(roomType, 'title') ?? ''}
                            />
                        </Col>
                    ))}
                </Row>
            )}
            {roomsFromType.length === 1 && (
                <Room
                    room={roomsFromType[0]}
                    index={1}
                    withTitle={false}
                    roomType={translatedField(roomType, 'title') ?? ''}
                />
            )}
        </RoomTypeBlock>
    );
};

export default RoomType;
