import { cssBreakpoint } from 'device';
import placeholderPicture from 'images/rental-placeholder.svg';
import { Carousel, Table } from 'react-bootstrap';
import styled from 'styled-components';

const FullHeightCarousel = styled(Carousel)`
    max-height: 75vh;
    margin-top: -65px;

    @media screen and ${cssBreakpoint('xl')} {
        margin-top: -80px;
    }
    .carousel-item {
        position: relative;
        width: 100%;

        @media screen and ${cssBreakpoint('xl')} {
            height: 75vh;
        }

        img {
            min-width: 100%;

            @media screen and ${cssBreakpoint('xl')} {
                height: 100%;
                left: 0;
                margin: auto;
                min-width: 100%;
                object-fit: cover;
                object-position: center center;
                position: absolute;
                right: 0;
            }

            &:after {
                background: url('${placeholderPicture}') no-repeat scroll 0 0;
                content: '';
                display: block;
                font-weight: 300;
                height: 100%;
                left: 0;
                line-height: 2;
                position: absolute;
                text-align: center;
                top: 0;
                width: 100%;
            }
        }
    }
`;

const RoomTypeBlock = styled.div.attrs(() => ({
    className: 'mb-3 ps-4',
}))`
    h4 {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 0;
        position: relative;

        &:before {
            border: 5px solid ${(props): string => props.theme.ivr.colors.golden['100']};
            border-radius: 50%;
            content: '';
            height: 5px;
            left: calc(-1rem - 5px);
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 5px;
        }
    }
`;

const Mentions = styled.p.attrs(() => ({
    className: 'mb-0',
}))`
    color: ${(props): string => props.theme.ivr.colors.black['100']};
    font-size: 0.8rem;
`;

const PriceTable = styled(Table).attrs(() => ({
    responsive: true,
}))`
    text-align: center;

    thead {
        th {
            background-color: ${(props): string => props.theme.ivr.colors.grey.l90};
            color: ${(props): string => props.theme.ivr.colors.golden['100']};
            position: sticky;
            top: -1px;
            z-index: 5;

            &:first-child {
                left: 0;
                text-align: left;
                z-index: 6;
            }
        }
    }

    tbody {
        td,
        th {
            border-bottom-width: 0;
            border-top: 1px solid ${(props): string => props.theme.ivr.colors.golden['100']};
        }

        td {
            vertical-align: middle;
        }

        th {
            background-color: ${(props): string => props.theme.ivr.colors.grey.l90};
            font-weight: 400;
            left: 0;
            position: sticky;
            text-align: left;
        }
    }
`;

const StyledHotelCalendar = styled.div`
    .rhc-month-name {
        color: ${(props): string => props.theme.ivr.colors.grey.d90};
        font-family: ${(props): string => props.theme.ivr.fonts.playfair};
        font-size: 1.5rem;
        font-style: italic;
        font-weight: 600;
        text-transform: capitalize;
    }

    .rhc-previous-btn,
    .rhc-next-btn {
        font-size: 30px;
        font-weight: 100;
    }

    table {
        width: 100%;
        @media screen and ${cssBreakpoint('md')} {
            width: 49%;
        }

        &:last-of-type {
            display: none;
            @media screen and ${cssBreakpoint('md')} {
                display: table;
            }
        }

        .rhc-day {
            font-weight: bold;
        }
    }
`;

const AvailabilityMention = styled.p.attrs(() => ({
    className: 'text-uppercase position-relative d-inline-block fw-bold',
}))`
    font-size: 0.8rem;
    padding-left: 2.4em;

    &::before {
        border-radius: 50%;
        border: 0.7em solid ${(props): string => props.theme.ivr.colors.golden['100']};
        content: '';
        display: inline-block;
        font-weight: 700;
        height: 0.7em;
        left: 0.4em;
        position: absolute;
        width: 0.7em;
    }
`;

const Price = styled.div.attrs(() => ({
    className: 'ps-2 ps-xl-0 text-left text-xl-center pt-2',
}))`
    background-color: ${(props): string => props.theme.ivr.colors.deepBlue['100']};
    color: ${(props): string => props.theme.ivr.colors.golden['100']};
    font-size: 0.9rem;
    font-weight: lighter;
    min-height: 61px;
    position: relative;

    @media screen and ${cssBreakpoint('xl')} {
        font-size: 1.3rem;
    }

    span {
        color: ${(props): string => props.theme.ivr.colors.white['100']};
        font-size: 1.7rem;
        font-weight: 500;
    }
`;

const Close = styled.div.attrs(() => ({
    className: 'd-block d-xl-none',
}))`
    background-color: ${(props): string => props.theme.ivr.colors.white['100']};
    height: 61px;
    position: absolute;
    right: 0;
    top: 0;
    width: 61px;

    svg {
        color: ${(props): string => props.theme.ivr.colors.black['100']};
        font-size: 3.6rem;
        position: absolute;
        right: 50%;
        transform: translateX(50%);
    }
`;

export {
    AvailabilityMention,
    Close,
    FullHeightCarousel,
    Mentions,
    Price,
    PriceTable,
    RoomTypeBlock,
    StyledHotelCalendar,
};
