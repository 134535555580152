import useLanguage from 'hooks/useLanguage';
import { ApiLocale, Translations } from 'types/api';

const useTranslatedFields = (): ((_entity: Translations<any> | undefined, _field: string) => any) => {
    const { ietfu } = useLanguage();
    return (
        entity: { translations: Record<ApiLocale, Record<string, any>> | undefined } | undefined,
        field: string,
    ): any => entity?.translations?.[ietfu as ApiLocale]?.[field] || '';
};

export default useTranslatedFields;
