import { formatISO } from 'date-fns';
import { enUS, fr } from 'date-fns/locale';
import useSSR from 'hooks/useSSR';
import React, { ComponentType, forwardRef, ReactElement, Suspense, useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { HotelDatepickerRef, InputElementProps } from 'react-hotel-datepicker';
import { useTranslation } from 'react-i18next';
import i18nEn from './en.json';
import i18nFr from './fr.json';
import { DatePickerContainer } from './styled';

type Props = {
    input: ComponentType<InputElementProps>;
    align?: 'left' | 'right';
    defaultStart: Date | null;
    defaultEnd: Date | null;
    noCheckInDates?: Array<Date>;
    noCheckOutDates?: Array<Date>;
};

const HotelDatepicker = React.lazy(() =>
    import('react-hotel-datepicker').then((module) => ({ default: module.HotelDatepicker })),
);

const DateRangePicker = forwardRef<HotelDatepickerRef, Props>(
    ({ align, input, defaultStart, defaultEnd, noCheckInDates, noCheckOutDates }, forwardedRef): ReactElement => {
        align = align ?? 'left';
        const { i18n } = useTranslation();
        const { setValue } = useFormContext<{ periodStart: string; periodEnd: string }>();
        const { browser } = useSSR();

        const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([defaultStart, defaultEnd]);
        const [periodStart, periodEnd] = dateRange;

        const locale = i18n.language === 'en' ? enUS : fr;
        const selectedI18n = i18n.language === 'en' ? i18nEn : i18nFr;

        const handleDateSelect = useCallback((start: Date | false, end: Date | false): void => {
            setDateRange([start ? start : null, end ? end : null]);
            setValue('periodStart', start ? formatISO(start, { representation: 'date' }) : '');
            setValue('periodEnd', end ? formatISO(end, { representation: 'date' }) : '');
        }, []);

        return (
            <React.Fragment>
                {browser && (
                    <Suspense fallback={<div />}>
                        <DatePickerContainer alignSection={align}>
                            <HotelDatepicker
                                locale={locale}
                                inputElement={input}
                                showTopBar={false}
                                onSelectRange={handleDateSelect}
                                defaultValue={{ start: periodStart, end: periodEnd }}
                                i18n={selectedI18n}
                                ref={forwardedRef}
                                noCheckInDates={noCheckInDates}
                                noCheckOutDates={noCheckOutDates}
                                preventContainerClose={false}
                            />
                        </DatePickerContainer>
                    </Suspense>
                )}
            </React.Fragment>
        );
    },
);

export default DateRangePicker;
