import useTranslatedFields from 'hooks/useTranslatatedField';
import React, { ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Room as RoomT } from 'types/api';

type Props = {
    room: RoomT;
    withTitle: boolean;
    index: number;
    roomType: string;
};

const Room = ({ room, withTitle, index, roomType }: Props): ReactElement => {
    const { t } = useTranslation('rental');
    const translatedField = useTranslatedFields();
    const view = translatedField(room, 'view');
    const title = translatedField(room, 'title');
    const description = translatedField(room, 'information');
    const capacity = room.capacity;
    const bathroomComment = translatedField(room, 'bathroomComment');
    const bed = room.bed ? translatedField(room.bed, 'title') : '';

    return (
        <>
            {withTitle && (
                <div className="pb-1">
                    <strong>{title ? title : `${roomType} ${index}`}</strong>
                </div>
            )}
            {view && (
                <p>
                    <Trans t={t} values={{ view }}>
                        rental:show.view
                    </Trans>
                </p>
            )}
            {bed && <p>{bed}</p>}
            {room.furnishings && room.furnishings.length > 0 && (
                <p>
                    <Trans
                        t={t}
                        values={{
                            furnishings: room.furnishings
                                .map((furnishing) => translatedField(furnishing, 'title'))
                                .join(' - '),
                        }}
                    >
                        rental:show.furnishings
                    </Trans>
                </p>
            )}
            {description && <p>{description}</p>}
            {capacity > 0 && (
                <p>
                    <Trans t={t} values={{ capacity }}>
                        rental:show.room_capacity
                    </Trans>{' '}
                </p>
            )}
            {room.bathroomType && (
                <strong>
                    <Trans t={t} tOptions={{ context: room.bathroomType }}>
                        rental:show.bathroom
                    </Trans>
                </strong>
            )}
            {bathroomComment && <p>{bathroomComment}</p>}
            {room.bathroomFurnishings && room.bathroomFurnishings.length > 0 && (
                <p className="ps-2">
                    {room.bathroomFurnishings
                        .map((bathroomFurnishing) => translatedField(bathroomFurnishing, 'title'))
                        .join(' - ')}
                </p>
            )}
        </>
    );
};

export default Room;
