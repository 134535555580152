import { useState } from 'react';
import { useWindowEventListener, useWindowSize } from 'rooks';

const useResponsiveMin = (min: number): boolean => {
    const { innerWidth } = useWindowSize();
    const [isUpTo, setIsUpTo] = useState(min < (innerWidth ?? 0));

    useWindowEventListener('resize', () => {
        setIsUpTo(min < (innerWidth ?? 0));
    });

    return isUpTo;
};

export { useResponsiveMin };
