import Picture from 'components/Rental/Show/Picture';
import useCloudinary from 'hooks/useCloudinary';
import useSSR from 'hooks/useSSR';
import React, { ReactElement, useCallback, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import { useWindowSize } from 'rooks';
import { Picture as PictureType } from 'types/api';
import { FullHeightCarousel } from './styled';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { scale } from '@cloudinary/url-gen/actions/resize';
import { dpr } from '@cloudinary/url-gen/actions/delivery';

type Props = {
    pictures: undefined | Array<PictureType>;
    title: string;
};

const RentalCarousel = ({ pictures, title }: Props): ReactElement => {
    const { innerWidth } = useWindowSize();
    const cloudinary = useCloudinary();
    const [active, setActive] = useState(0);
    const [showLightbox, setShowLightbox] = useState(false);
    const { browser } = useSSR();

    /*const getPictureUrl = useCallback(
        (picture: PictureType): string =>
            picture.cdnId
                ? cloudinary
                      .image(`${picture.cdnId}.${picture.format}`)
                      .resize(scale(innerWidth ?? 'auto'))
                      .delivery(dpr('auto'))
                      .toURL()
                : picture.contentUrl,
        [cloudinary, innerWidth],
    );*/
    const getPictureUrl = useCallback(
        (picture: PictureType): string => {
            if (picture.cdnId && picture.cdnId.includes('.')) {
                const pictureUrl = `${picture.cdnId}`;
                const imageUrl = `https://img.imageboss.me/orion/width/1440/format:auto/${encodeURIComponent(pictureUrl)}`;
                return imageUrl;
            } else {
                return picture.contentUrl;
            }
        },
        []
    );    


    const length = pictures?.length || 0;

    return (
        <React.Fragment>
            <FullHeightCarousel
                onSlide={(index: number): void => setActive(index)}
                indicators={false}
                interval={500000}
                activeIndex={active}
                onSelect={(index: number): void => setActive(index)}
            >
                {pictures &&
                    pictures.map((picture) => (
                        <Carousel.Item key={picture.id} onClick={(): void => setShowLightbox(true)}>
                            <Picture picture={picture} title={title} />
                        </Carousel.Item>
                    ))}
                {(pictures || []).length === 0 && (
                    <Carousel.Item>
                        <Picture picture={undefined} title={title} />
                    </Carousel.Item>
                )}
            </FullHeightCarousel>
            {browser && pictures && showLightbox && undefined !== pictures[active] && (
                <Lightbox
                    mainSrc={getPictureUrl(pictures[active])}
                    nextSrc={getPictureUrl(pictures[(active + 1) % length])}
                    prevSrc={getPictureUrl(pictures[(active + length - 1) % length])}
                    onCloseRequest={(): void => setShowLightbox(false)}
                    onMovePrevRequest={(): void => setActive((active + length - 1) % length)}
                    onMoveNextRequest={(): void => setActive((active + 1) % length)}
                />
            )}
        </React.Fragment>
    );
};

export default RentalCarousel;
