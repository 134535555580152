import { useRentalPriceRange } from 'hooks/usePrices';
import React, { ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { IoIosClose } from 'react-icons/io';
import { Rental } from 'types/api';
import { Close, Price } from './styled';

type Props = {
    rental: Rental;
    onClose?: () => any;
};

const FromPrice = ({ onClose, rental }: Props): ReactElement => {
    const { t } = useTranslation('rental');
    const data = useRentalPriceRange(rental);

    return (
        <Price>
            <Trans t={t} values={{ value: data.min }}>
                rental:card.price
            </Trans>
            <Close onClick={onClose}>
                <IoIosClose />
            </Close>
        </Price>
    );
};

export default FromPrice;
