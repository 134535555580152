import styled from 'styled-components';

const DatePickerContainer = styled.div<{ alignSection: 'left' | 'right' }>`
    section {
        left: ${(props): string => (props.alignSection === 'left' ? '0' : 'auto')};
        right: ${(props): string => (props.alignSection === 'right' ? '0' : 'auto')};
        z-index: 10;
    }
`;

export { DatePickerContainer };
